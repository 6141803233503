/* eslint-disable react/prop-types */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { HelpRounded, ArrowDropDown } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import CalcProdForm from './CalcProdForm';
import ProdTable from './ProdTable';

export default function ProductivityHelp({ produtividade, setProductivity }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton href="#" variant="text" onClick={handleClickOpen}>
        <HelpRounded />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          display="flex"
          justifyContent="center"
          gap={1}
          alignItems="center"
        >
          <ArrowDropDown />
          <Typography variant="h5">Calculo de Produtividade</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">
              A produtividade é calculada por meio da multiplicação entre o
              número de animais por hectare (Lotação Animal), o ganho médio
              diário de peso (GMD) por animal em Kg e o número de dias de
              pastejo, sendo que o resultado se dá em KG por hectare. Os valores
              considerados padrões pelo sistema estão descritos abaixo.
            </Typography>
            {/* Tabela de produtividade */}
            <ProdTable />
          </DialogContentText>
          <Grid xs={12}>
            <CalcProdForm
              produtividade={produtividade}
              setProductivity={setProductivity}
              close={handleClose}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
