import { useState, useContext } from 'react';
import { Typography, Grid, useTheme, Card, Box } from '@mui/material';
import embrapa from 'assets/embrapa.png';
import Footer from 'components/Footer';
import { ConfigContext } from 'context';
import InputForm from 'components/InputForm';
import ResultsDisplay from 'components/ResultsDisplay';
import publication from 'assets/publicação embrapa snippet.png';
import publication2 from 'assets/publicação2.png';
import publication3 from 'assets/publicação3.png';
import publication4 from 'assets/publicação4.png';

const Calculator = () => {
  const { config } = useContext(ConfigContext);
  const defaultIntervals = {
    costInterval: 50,
    priceInterval: 0.5,
    productivityInterval: 50,
  };
  const theme = useTheme();
  const costInterval = config?.costInterval || defaultIntervals.costInterval;
  const priceInterval = config?.priceInterval || defaultIntervals.priceInterval;
  const productivityInterval =
    config?.productivityInterval || defaultIntervals.productivityInterval;

  // Inicializar estados como strings
  const [cost, setCost] = useState('1900,00');
  const [price, setPrice] = useState('9,50');
  const [productivity, setProductivity] = useState(300);

  const [priceList, setPriceList] = useState([]);
  const [costList, setCostList] = useState([]);
  const [productivityList, setProductivityList] = useState([]);
  const [results, setResults] = useState(null);
  const [sortedResults, setSortedResults] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const calcular = (numericCost, numericPrice) => {
    const prices = [
      numericPrice - 2 * priceInterval,
      numericPrice - priceInterval,
      numericPrice,
      numericPrice + priceInterval,
      numericPrice + 2 * priceInterval,
    ];
    const productivities = [
      productivity - 2 * productivityInterval,
      productivity - productivityInterval,
      productivity,
      productivity + productivityInterval,
      productivity + 2 * productivityInterval,
    ];
    const costs = [
      numericCost - 2 * costInterval,
      numericCost - costInterval,
      numericCost,
      numericCost + costInterval,
      numericCost + 2 * costInterval,
    ];

    setPriceList(prices);
    setProductivityList(productivities);
    setCostList(costs);

    const priceProdVariation = prices.map((p) =>
      productivities.map((prod) => prod * p - numericCost),
    );
    const priceCostVariation = prices.map((p) =>
      costs.map((c) => productivity * p - c),
    );
    const prodCostVariation = costs.map((c) =>
      productivities.map((prod) => prod * numericPrice - c),
    );

    const combinedArray = [
      ...priceCostVariation.flat(),
      ...priceProdVariation.flat(),
      ...prodCostVariation.flat(),
    ];
    const minValue = Math.min(...combinedArray);
    const maxValue = Math.max(...combinedArray);
    setMinValue(minValue);
    setMaxValue(maxValue);

    setResults({ priceProdVariation, priceCostVariation, prodCostVariation });
    setSortedResults([...combinedArray].sort((a, b) => a - b));

    document.getElementById('result').scrollIntoView({ behavior: 'smooth' });
  };

  // Converter strings para números para o cálculo do lucro médio
  const numericCost = parseFloat(cost.replace(/\./g, '').replace(',', '.'));
  const numericPrice = parseFloat(price.replace(/\./g, '').replace(',', '.'));
  const averageProfit = numericPrice * productivity - numericCost;
  const handleDownward = () =>
    document
      .getElementById('arrowDownward')
      .scrollIntoView({ behavior: 'smooth' });

  return (
    <Grid
      container
      xs={12}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Card
        sx={{
          maxWidth: '800px',
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
        }}
      >
        <Box
          id="arrows"
          sx={{
            '.arrow': {
              opacity: 0.2,
              position: 'fixed',
              bottom: 0,
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 10,
            },
            '.arrow span': {
              display: 'block',
              width: '1rem',
              height: '1rem',
              borderBottom: '5px solid black',
              borderRight: '5px solid black',
              transform: 'rotate(45deg)',
              margin: '-10px',
              animation: 'animate 2s infinite',
            },

            '.arrow span:nth-child(2)': {
              animationDelay: '-0.2s',
            },

            '.arrow span:nth-child(3)': {
              animationDelay: '-0.4s',
            },
            '@keyframes animate': {
              '0%': {
                opacity: 0,
                transform: 'rotate(45deg) translate(-20px, -20px)',
              },
              '50%': {
                opacity: 1,
              },
              '100%': {
                opacity: 0,
                transform: 'rotate(45deg) translate(20px, 20px)',
              },
            },
          }}
        >
          <div className="arrow" onClick={handleDownward}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Box>
        <InputForm
          cost={cost}
          setCost={setCost}
          price={price}
          setPrice={setPrice}
          productivity={productivity}
          setProductivity={setProductivity}
          onCalculate={calcular}
        />
        <Grid xs={12} justifyContent="center" id="result">
          {results && (
            <>
              <Typography
                variant="h4"
                component="h4"
                sx={{ textAlign: 'center' }}
              >
                Lucro (R$/hectare)
              </Typography>
              <ResultsDisplay
                results={results}
                sortedResults={sortedResults}
                minValue={minValue}
                maxValue={maxValue}
                averageProfit={averageProfit}
                priceList={priceList}
                costList={costList}
                productivityList={productivityList}
                price={numericPrice}
                cost={numericCost}
                productivity={productivity}
              />
            </>
          )}
        </Grid>
        {/* Publicação */}
        {results && (
          <Grid
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography
              variant="subtitle1"
              color={theme.palette.primary.main}
              target="_blank"
              component="a"
              href="https://ainfo.cnptia.embrapa.br/digital/bitstream/item/222019/1/Sudao-3.pdf"
            >
              Clique aqui para acessar a publicação de 2020.
            </Typography>
            <Grid container xs={12} padding={2} justifyContent="center">
              <Grid
                item
                component="img"
                borderRadius={1}
                maxWidth="400px"
                width="100%"
                height="auto"
                src={publication}
                alt="Publicação Impacto Bioeconômico Capim Sudão"
              />
            </Grid>
            <Typography variant="h6">Outras Publicações:</Typography>

            <Grid container xs={12} padding={2} justifyContent="center">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                target="_blank"
                component="a"
                href="https://www.embrapa.br/busca-de-publicacoes/-/publicacao/965444/capim-sudao-brs-estribo-cultivar-de-capim-sudao-para-pastejo#"
              >
                Capim-sudão BRS Estribo: cultivar de capim-sudão para pastejo
              </Typography>
              <Grid
                item
                component="img"
                borderRadius={1}
                maxWidth="400px"
                width="100%"
                height="auto"
                src={publication2}
                alt="Publicação Impacto Bioeconômico Capim Sudão"
              />
            </Grid>
            <Grid container xs={12} padding={2} justifyContent="center">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                target="_blank"
                component="a"
                href="https://www.embrapa.br/busca-de-publicacoes/-/publicacao/1112617/pasto-sobre-pasto-estrategias-de-manejo-para-uso-de-mesclas-forrageiras-de-inverno-e-verao-visando-melhor-distribuicao-de-forragem"
              >
                Capim-sudão BRS Estribo: cultivar de capim-sudão para pastejo
              </Typography>
              <Grid
                item
                component="img"
                borderRadius={1}
                maxWidth="400px"
                width="100%"
                height="auto"
                src={publication3}
                alt="Publicação Impacto Bioeconômico Capim Sudão"
              />
            </Grid>
            <Grid container xs={12} padding={2} justifyContent="center">
              <Typography
                variant="subtitle1"
                color={theme.palette.primary.main}
                target="_blank"
                component="a"
                href="https://www.embrapa.br/busca-de-publicacoes/-/publicacao/1031369/aspectos-relativos-a-implantacao-e-manejo-de-capim-sudao-brs-estribo"
              >
                Aspectos relativos à implantação e manejo de capim-sudão BRS
                Estribo
              </Typography>
              <Grid
                item
                component="img"
                borderRadius={1}
                maxWidth="400px"
                width="100%"
                height="auto"
                src={publication4}
                alt="Publicação Impacto Bioeconômico Capim Sudão"
              />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center" mt={4} mb={4}>
          <img src={embrapa} alt="logo embrapa" width="150px" />
          <Footer />
        </Grid>
        <Box
          sx={{
            position: 'absolute',
            zIndex: 100,
            bottom: 0,
            height: '2rem',
            width: '100%',
            background: 'inherit',
          }}
        />
      </Card>
    </Grid>
  );
};

export default Calculator;
