/* eslint-disable react/prop-types */
// ResultsDisplay.js
import { Box, Grid, Typography } from '@mui/material';
/* import DownloadResult from 'components/DownloadResult'; */
import Overview from 'components/Overview';
import ScenariosDescription from 'components/ScenariosDescription';
import Results from 'components/Results';
import util from 'helpers/util';
import DownloadResult from 'components/DownloadResult';
import VideoTutorial from 'components/VideoTutorial';

const ResultsDisplay = ({
  results,
  sortedResults,
  minValue,
  maxValue,
  averageProfit,
  priceList,
  costList,
  productivityList,
  price,
  cost,
  productivity,
}) => {
  return (
    <Grid xs={12} mt={1}>
      {/* <Grid container xs={12} justifyContent="center">
        <DownloadResult />
      </Grid> */}
      <Overview
        minvalue={minValue}
        maxValue={maxValue}
        averageProfit={averageProfit}
      />
      <ScenariosDescription
        price={price}
        cost={cost}
        productivity={productivity}
      />
      <Box margin="1rem">
        <Typography variant="h5" textAlign="center" fontWeight="bold">
          ANÁLISE TRÍPLICE DE CENÁRIOS BIOECONÔMICOS DO LUCRO POR HECTARE
        </Typography>
      </Box>
      <Box margin="1rem" display="flex" justifyContent="center" gap="1rem">
        <DownloadResult />
        <VideoTutorial />
      </Box>

      {/* Resultados para Custo */}
      <Results
        order={'1ª Análise'}
        title={`Lucro por hectare variando o preço e a produtividade`}
        footer={`Fixando o custo em ${util.methods.utilCurrencyFormatter(
          cost,
        )}/hectare`}
        rowText="Preço (R$/kg vivo)"
        colText="Produtividade (kg/hectare)"
        results={results.priceProdVariation}
        sortedResults={sortedResults}
        rowLabel={priceList}
        colLabel={productivityList}
        max={maxValue}
        min={minValue}
        type="cost"
        price={price}
        cost={cost}
        prod={productivity}
      />
      {/* Resultados para Preço */}
      <Results
        order={'2ª Análise'}
        title={`Lucro por hectare variando o custo e a produtividade`}
        footer={`Fixando o preço em ${util.methods.utilCurrencyFormatter(
          price,
        )}/kg PV`}
        rowText="Custo (R$ / hectare)"
        colText="Produtividade (kg/hectare)"
        results={results.prodCostVariation}
        sortedResults={sortedResults}
        rowLabel={costList}
        colLabel={productivityList}
        max={maxValue}
        min={minValue}
        type="price"
        price={price}
        cost={cost}
        prod={productivity}
      />
      {/* Resultados para Produtividade */}
      <Results
        order={'3ª Análise'}
        title={`Lucro por hectare variando o preço e custo`}
        footer={`Fixando a produtividade em em ${productivity}kg PV/hectare`}
        colText="Custo (R$ / hectare)"
        results={results.priceCostVariation}
        sortedResults={sortedResults}
        rowLabel={priceList}
        colLabel={costList}
        max={maxValue}
        min={minValue}
        type="productivity"
        price={price}
        cost={cost}
        prod={productivity}
      />
      <Typography variant="subtitle2" textAlign="center" mt={2}>
        *O lucro é obtido pela subtração entre o Valor da Produção (VP) e o
        Custo Operacional Efetivo (COE).
      </Typography>
    </Grid>
  );
};

export default ResultsDisplay;
