/* eslint-disable react/prop-types */
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
/* import chroma from 'chroma-js'; */
import util from 'helpers/util';
import useCellColor from 'hooks/useCellColor';

const Results = ({
  sortedResults,
  rowText,
  colText,
  results,
  order,
  rowLabel,
  colLabel,
  isPriceCol,
  isPriceRow,
  min,
  max,
  title,
  footer,
  price,
  cost,
  prod,
  type,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  function formatCurrencyAndPreserveSign(value) {
    // Formata o valor usando utilCurrencyFormatter
    const formatted = util.methods.utilCurrencyFormatter(value);

    // Extrai a parte numérica formatada, mantendo o sinal negativo se existir
    const isNegative = value < 0;
    const numericPart = formatted.split('$')[1];
    return isNegative ? `-${numericPart}` : `${numericPart}`;
  }

  // Inverter os resultados e os rótulos para a visualização de gráfico/matriz
  const invertedResults = Array.isArray(results) ? [...results].reverse() : [];

  return (
    <Grid
      container
      xs={12}
      display="flex"
      justifyContent="center"
      alignItems="center"
      margin="4rem 0"
      padding="4rem 0"
      sx={{
        border: `2px solid ${theme.palette.grey[700]}`,
        boxShadow: `2px 2px 66px -7px rgba(156,39,176,0.53)`,
      }}
    >
      {/* Tabela principal */}
      <TableContainer component={Paper}>
        <Typography variant="h3" sx={{ textAlign: 'center', padding: '1rem' }}>
          {order}
        </Typography>
        <Typography variant="h4" sx={{ textAlign: 'center', padding: '1rem' }}>
          {title}
        </Typography>
        <Table>
          {/* Eixo Y - Rótulos das linhas */}
          <Box
            display="flex"
            width="100%"
            justifyContent="center"
            gap="0.5rem"
            maxHeight="fit-content"
          >
            <Box
              justifyContent="flex-end"
              alignContent="flex-start"
              maxWidth={isMobile ? '5rem' : '8rem'}
              display="flex"
            >
              <Box
                display="flex"
                flexDirection="column-reverse"
                justifyContent="flex-end"
                alignContent="center"
                maxWidth="2rem"
                gap={isMobile ? '1rem' : '2rem'}
                paddingTop={isMobile ? '0.8rem' : '1.5rem'}
              >
                {rowLabel.map((label, index) => (
                  <Typography
                    key={index}
                    sx={{
                      textAlign: 'center',
                      fontStyle: 'italic',
                      fontSize: isMobile ? '0.8rem' : '1rem',
                    }}
                  >
                    {isPriceRow
                      ? util.methods.utilCurrencyFormatter(label).split('$')[1]
                      : label}
                  </Typography>
                ))}
              </Box>
            </Box>
            <TableBody>
              {invertedResults.map((linha, i) => (
                <TableRow
                  key={i}
                  sx={{
                    borderTop:
                      i === 0 ? `2px solid ${theme.palette.grey[700]}` : '',
                    borderBottom:
                      i === 4 ? `2px solid ${theme.palette.grey[700]}` : '',
                    borderRight: `2px solid ${theme.palette.grey[700]}`,
                    borderLeft: `2px solid ${theme.palette.grey[700]}`,
                  }}
                >
                  {linha.map((valor, j) => (
                    <TableCell
                      key={j}
                      style={{
                        backgroundColor: useCellColor(valor, min, max),
                        fontSize: isMobile ? '0.8rem' : '1rem',
                        padding: isMobile ? '0.5rem 0.2rem' : '1rem',
                        border:
                          i === 2 && j === 2
                            ? `4px solid ${theme.palette.grey[900]}`
                            : '',
                        borderTop:
                          (i === 1 || i === 4) && j > 0 && j < 4
                            ? `3px solid ${theme.palette.grey[800]}`
                            : i === 2 && j === 2
                            ? `4px solid ${theme.palette.grey[900]}`
                            : '',
                        borderRight:
                          i > 0 && i < 4 && (j === 0 || j === 3)
                            ? `3px solid ${theme.palette.grey[800]}`
                            : i === 2 && j === 2
                            ? `4px solid ${theme.palette.grey[900]}`
                            : '',
                      }}
                    >
                      {/* {(Math.round(valor * 100) / 100).toFixed(2).replace('.', ',')} */}
                      {formatCurrencyAndPreserveSign(valor).split(',')[0]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              <TableRow>
                {/* Eixo X - Rótulos das colunas */}
                {colLabel.map((label, index) => (
                  <TableCell
                    key={index}
                    sx={{
                      textAlign: 'center',
                      fontSize: isMobile ? '0.8rem' : '1rem',
                      padding: isMobile ? '1rem 0' : '1rem',
                    }}
                  >
                    {isPriceCol
                      ? util.methods.utilCurrencyFormatter(label).split('$')[1]
                      : label}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Box>
          {/* <Box display="flex" justifyContent="center" mb={2}>
            <Typography
              variant="caption"
              textAlign="center"
              fontStyle="italic"
              sx={{
                textAlign: 'center',
                fontSize: isMobile ? '0.8rem' : '1rem',
                padding: isMobile ? '1rem 0' : '1rem',
              }}
            >
              {colText}
            </Typography>
          </Box> */}
        </Table>
        <Typography variant="h4" sx={{ textAlign: 'center', padding: '1rem' }}>
          {footer}
        </Typography>
      </TableContainer>
      {/* Legenda ou qualquer outra informação adicional pode ser colocada aqui */}
    </Grid>
  );
};

export default Results;
